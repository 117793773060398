import React, { useState,useEffect} from "react";
import { ToastContainer, toast } from 'react-toastify';
import { validateData } from "../../util";
import { useHistory } from "react-router-dom";
import axios from "../../api";

export default function ResetEmail(props) {
  const [token, setToken] = useState("");
  const [data, setData] = useState({});
  const [validateErr, setValidateErr] = useState({});
  const [redirect, setRedirect] = useState(false);

  useEffect(() =>{
    let url =  new URLSearchParams(props.location.search);
   
    let token = url.get("token");
    setToken(token);
  },[props.location.search]);

const history = useHistory();
  const handleInput = async(e) => {
    const {name, value} = e.target;
    setData({...data, [name]: value});
    validateData(name, value, validateErr, setValidateErr);
  }
  const isValid = () => {
    let valid = true;
    if (
      validateData("password", data?.password, validateErr, setValidateErr) === false
    ) {
      valid = false;
    }
    if (
      validateData("confirm", data?.confirm, validateErr, setValidateErr) === false
    ) {
      valid = false;
    }
    return valid;
  };
  const cleanAllField = () => {
    setData({});
  };
  const handleSubmit = async(e) => {
    e.preventDefault()
    const validateData = isValid(data);
    if (validateData) {
      if(data.password === data.confirm){    
      try {

       const res = await axios.post("/auth/verify", {
      token,
      password: data.password
    });
           
    if(res.status === 200){
             
        toast.success('Data Updated Successfully', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => {
          setRedirect(true);
          
        }
      }); 
      cleanAllField();
    } 
  
  }
   catch (err) {
         toast.error("Failed to Change Password", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
  });
}
      
    }else{
      toast.error("Password not match", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
  });
     
     }
   
  }
}
if (redirect === true) {
  history.push('/')
}
  return (
    <>
    <ToastContainer />
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                   Reset Password
                  </h6>
                </div>
  
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form onSubmit={handleSubmit} >
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input    
                    onChange={(e)=>handleInput(e)}
                      name="password"
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      value ={data?.password || ''}
                    />
                    {validateErr && (
                      <small style={{ color: "red", fontSize: "14px" }}>
                        {validateErr?.password}
                      </small>
                    )}
                  </div>

                   <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Confirm Password
                    </label>
                    <input
                     onChange={(e)=>handleInput(e)}
                      name="confirm"
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      value={data?.confirm || ''}
                    />
                     {validateErr && (
                      <small style={{ color: "red", fontSize: "14px" }}>
                        {validateErr?.confirm}
                      </small>
                    )}
                   </div>  


                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                     Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
   
          </div>
        </div>
      </div>
    </>
  );
}
