import React, { useCallback } from "react";
import { deleteLocation, deleteBlog,deleteVaccine,deleteCategory ,deleteCategoryVaccine} from "../../util/index";
import { toast } from 'react-toastify';

const NotificationDropdown = ({handleEditCard,fetch, id, type, setDeleted, row,showdropdownpopover,setDropdownPopoverId }) => {
  // dropdown props
  // const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
 
  // const openDropdownPopover = () => {
  //   createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
  //     placement: "left-start",
  //   });
  //   setDropdownPopoverShow(true);
  // };
  // const closeDropdownPopover = () => {
  //   setDropdownPopoverShow(false);
  // };

  const handleFetch = React.useCallback(() => {
    fetch();
  }, [fetch]);

  const handleDelete = async(id) => {
    if(type === "Blogs"){
      const res = await deleteBlog(id);
      if(res?.status === 400){
        return toast.error(res?.mesage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
          theme: 'colored',
        });
      }
      toast.success("Blog Deleted Successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500,
        theme: 'colored',
      });
      return handleFetch();
    }
    
    if(type === "CategoryVaccine"){

      const res = await deleteCategoryVaccine(id);
      if(res.status){
        toast.success("Category Vaccine Deleted Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
          theme: 'colored',
        })
      }
      else{
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
          theme: 'colored',
        });
      }
      return handleFetch();
    }

    if(type === "Vaccine"){
      const res = await deleteVaccine(id);
      if(res.status){
        toast.success("Vaccine Deleted Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
          theme: 'colored',
        })
      }
      else{
        toast.error("Failed To Delete", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
          theme: 'colored',
        });
      }
      return handleFetch();
    }


    if(type === "Category"){
      const res = await deleteCategory(id);
      if(res.status){
        toast.success("Category Deleted Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
          theme: 'colored',
        });
      }
      else{
        toast.error("Failed To Delete", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
          theme: 'colored',
        });
      }
      return handleFetch();
    }


    const res = await deleteLocation(id);
    // getCountData()
      if(res?.status === 409){
        return toast.error(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
          theme: 'colored',
        });
      }
      toast.success("Data Deleted Successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500,
        theme: 'colored',
      });
      if (setDeleted) {
        setDeleted(true);
      }
      handleFetch();
  }
  const handleClickOutside = useCallback(() => {
    setDropdownPopoverId('')
  },[setDropdownPopoverId]);
  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);
 
  return (
    <div>
      <a
        className="text-blueGray-500 py-1 px-3"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          showdropdownpopover?setDropdownPopoverId(''):setDropdownPopoverId(id)
        }}
      >
        <i className="fas fa-ellipsis-v"></i>
      </a>
      <div
        ref={popoverDropdownRef}
        style = {{position:'absolute',right:'50px'}}
        className={
          (showdropdownpopover ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        
         {/* {type !== "CategoryVaccine" &&   */}
        
        <a
          href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={(e) => {
            e.preventDefault();
            handleEditCard(row);
          }}
        >
          Edit
        </a>
{/* } */}
        
        <a
          href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={()=> handleDelete(id)}
        >
          Delete
        </a>
      </div> 
    </div>
  );
};

export default NotificationDropdown;
