import React from "react";
import PropTypes from "prop-types";

export default function CardTableUsersData({ color, title, data, cols }) {
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                {title}
              </h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table  className=" bg-transparent">
            <thead>
              <tr>
                {cols &&
                  cols.map((col, ind) => (
                    <th
                      key={ind}
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                    >
                      {col}
                    </th>
                  ))}
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                ></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((dt,index) => (
                <tr key={index}>
                  <th style={{ width:'16%', wordBreak:'break-all', textAlign:'left'}} >
                    <span
                    style={{marginLeft:"1.5rem", fontSize:"13px", float:"left"}}
                    >
                      {dt.name ? `${dt.name}` : "--"}
                    </span>
                  </th>
                  <td className="border-t-0 px-6  border-l-0 border-r-0 text-xs  p-4" style={{ width:'20%', wordBreak:'break-all', fontSize:'13px'}}>
                    {dt.email ? `${dt.email}` : "--"}
                  </td>
                  <td className="border-t-0 px-6  border-l-0 border-r-0 text-xs  p-4" style={{ width:'16%', wordBreak:'break-all',fontSize:'13px'}} >
                    {dt.countries ? `${dt.countries}` : "--"}
                  </td>
                  <td  style={{ width:'40%', wordBreak:'break-all',fontSize:'13px'}} className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs  p-4">{dt.destinations ? `${dt.destinations}` : "--"}
                  </td>
                  <td  className="border-t-0 px-6  border-l-0 border-r-0 text-xs  p-4" style={{ width:'16%', wordBreak:'break-all',fontSize:'13px'}} >
                    {dt.age ? `${dt.age}` : "--"}
                  </td>
                  <td style={{textTransform:'capitalize',float:'left',fontSize:"13px", marginLeft:'1.75rem'}}>
                    {dt.gender ? `${dt.gender}` : "--"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

CardTableUsersData.defaultProps = {
  color: "light",
};

CardTableUsersData.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
