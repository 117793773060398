import React, { useEffect, useState } from "react";

// components
import { getLocations } from "../../util";
import CardAddCountries from "components/Cards/CardAddCountries";
// import CardTable from "components/Cards/CardTable.js";
import EditCountry from "components/Update/CardEditCountries";

import CardTable from "components/Cards/CardTableNew";

export default function Country() {
  const [continents, setContinents] = useState([]);
  const [countries, setCountry] = useState([]);
  const [editCard, setEditcard] = useState({});
  const [showmap,setShowMap] = useState(true)

  const handleEditCard = (row) => {
    setEditcard(row);
  }

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if(editCard.id){
      setShowMap(false)
    }
    else{
      setShowMap(true)
    }
  }, [editCard]);

  const fetch = async () => {
    const continents = await getLocations("continent");
    if (continents.locations) setContinents(continents.locations);
    const countries = await getLocations("country");
    if (countries.locations) return setCountry(countries.locations);
  };
 
  return (
    <>
      <div className="flex flex-wrap">
        {(editCard && editCard.id )? (
          <div className="w-full lg:w-12/12 px-4">
            <EditCountry
              fetch={fetch}
              location={editCard}
              handleEditCard={handleEditCard}
              continents={continents}
              setEditcard={setEditcard}
            />
          </div>
        ):<div className="w-full lg:w-12/12 px-4">
                 <CardAddCountries fetch={fetch} locations={continents} showmap = {showmap}/>

      </div>}
        <div className="w-full mb-12 px-4">
          <CardTable
            fetch={fetch}
            title="Countries"
            handleEditCard={handleEditCard}
            cols={["Name", "Continent", "Trending","Latitude","Longitude", "Image"]}
            data={countries}
            isCountry={true}
          />
        </div>
      </div>
    </>
  );
}
