export const Continents =[
    // {
    //   name: "Asia",
    //   latitude: "61.68350451675011",
    //   longitude: "96.39286222890625",
    // },
    // {
    //   name: "Europe",
    //   latitude: "47.31855324753012",
    //   longitude: "20.02414885000001",
    // },
    // {
    //   name: "Africa",
    //   latitude: "15.721178178816588",
    //   longitude: "28.43967619375001",
    // },
    // {
    //   name: "Antarctica",
    //   latitude: "-78.65709677819639",
    //   longitude: "23.803293749999867",
    // },
    // {
    //   name: "Australia/Oceania",
    //   latitude: "-25.18363562094569",
    //   longitude: "134.89704374999985",
    // },
    // {
    //   name: "North America",
    //   latitude: "41.74080718513628",
    //   longitude: "-96.958425",
    // },
    {
      name: "South Asia",
      latitude: "27.449790329784268",
      longitude: "87.1875",
    },
    {
      name: "Africa",
      latitude: "15.721178178816588",
      longitude: "28.43967619375001",
    },
    {
      name: "South America",
      latitude: "-11.41780908893625",
      longitude: "-58.8109951929688",
    },
     
]