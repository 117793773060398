/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import BlogLogo from "../../assets/sliderLogo/Blog.svg"
import CategoryLogo from "../../assets/sliderLogo/category.svg"
import ContinentLogo from "../../assets/sliderLogo/continents.svg"
import CountryLogo from "../../assets/sliderLogo/country.svg"
import dashboardLogo from "../../assets/sliderLogo/Dashboard.svg"
import LogoutLogo from "../../assets/sliderLogo/Logout.svg"
import statecityLogo from "../../assets/sliderLogo/statecity.svg"
import usersLogo from "../../assets/sliderLogo/users.svg"
import VaccinecategoryLogo from "../../assets/sliderLogo/Vaccinecategory.svg"
import vaccineLogo from "../../assets/sliderLogo/vaccine.svg"
import { ToastContainer, toast } from "react-toastify";
// import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
// import UserDropdown from "components/Dropdowns/UserDropdown.js";
import logo from "../../assets/img/travelrxlogo.png"
export default function Sidebar(props) {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const [redirect,setRedirect] = React.useState(false)
  const handleLogout = () => {
      toast.success("Logout is successful", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => {
          localStorage.clear()
          setRedirect(true)
        }
      });
  }
  if(redirect === true){
     props.history.push('/')
  }
  return (
    <>
    <ToastContainer/>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
             <img src ={logo} alt ="..."/>
          </Link>
                    <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                     <img src ={logo} alt ="..."/>
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            {/* <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Admin Layout Pages
            </h6> */}
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                style={{display:"flex"}}
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/dashboard"
                >
                         <img
                  src={dashboardLogo} alt ="..."
                  style={{height:"15px",marginRight:"8px"}}
                  className={
                      
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "opacity-75"
                      : "text-blueGray-300")
                  }
                   
                  ></img>{" "}   
                  Dashboard
                </Link>
              </li>

              <li className="items-center">
                <Link
                  style={{display:"flex"}}
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/continent") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/continent"
                >
                  <img
                  src={ContinentLogo} alt ="..."
                  style={{height:"15px",marginRight:"8px"}}
                  className={
                      
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "opacity-75"
                      : "text-blueGray-300")
                  }
                   
                  ></img>{" "} 
                  Continents
                </Link>
              </li>
              <li className="items-center">
                <Link
                 style={{display:"flex"}}
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/country") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/country"
                >
                 <img
                  src={CountryLogo} alt ="..."
                  style={{height:"15px",marginRight:"8px"}}
                  className={
                      
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "opacity-75"
                      : "text-blueGray-300")
                  }
                   
                  ></img>{" "} 
                  Country
                </Link>
              </li>
              {/* <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/state") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/state"
                >
                  <i
                    className={
                      "fas fa-tools mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/state") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                 state
                </Link>
              </li> */}
              <li className="items-center">
                <Link
                 style={{display:"flex"}}
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/city") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/city"
                >
                    <img
                  src={statecityLogo} alt ="..."
                  style={{height:"15px",marginRight:"8px"}}
                  className={
                      
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "opacity-75"
                      : "text-blueGray-300")
                  }
                   
                  ></img>{" "} 
                  State/City/Activities
                </Link>
              </li>
              <li className="items-center">
                <Link
                 style={{display:"flex"}}
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/vaccine") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/vaccine"
                >
                  <img
                  src={vaccineLogo} alt ="..."
                  style={{height:"15px",marginRight:"8px"}}
                  className={
                      
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "opacity-75"
                      : "text-blueGray-300")
                  }
                   
                  ></img>{" "} 
                  vaccine
                </Link>
              </li>
              <li className="items-center">
                <Link
                 style={{display:"flex"}}
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/category") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/category"
                >
                    <img
                  src={CategoryLogo} alt ="..."
                  style={{height:"15px",marginRight:"8px"}}
                  className={
                      
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "opacity-75"
                      : "text-blueGray-300")
                  }
                   
                  ></img>{" "} 
                  category
                </Link>
              </li>
              
              {/* <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/activities") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/activities"
                >
                  <i
                    className={
                      "fas fa-tools mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/activities") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Activities
                </Link>
              </li> */}
              <li className="items-center">
                <Link
                 style={{display:"flex"}}
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/blog") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/blog"
                >
                   <img
                  src={BlogLogo} alt ="..."
                  style={{height:"15px",marginRight:"8px"}}
                  className={
                      
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "opacity-75"
                      : "text-blueGray-300")
                  }
                   
                  ></img>{" "} 
                  Blogs
                </Link>
              </li>
              <li className="items-center">
                <Link
                 style={{display:"flex"}}
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/users") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/users"
                >
                   <img
                  src={usersLogo} alt ="..."
                  style={{height:"15px",marginRight:"8px"}}
                  className={
                      
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "opacity-75"
                      : "text-blueGray-300")
                  }
                   
                  ></img>{" "} 
                  Users
                </Link>
              </li>
              <li className="items-center">
                <Link
                 style={{display:"flex"}}
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/categories-vaccines") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/categories-vaccines"
                >
                    <img
                  src={VaccinecategoryLogo} alt ="..."
                  style={{height:"15px",marginRight:"8px"}}
                  className={
                      
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "opacity-75"
                      : "text-blueGray-300")
                  }
                   
                  ></img>{" "} 
                  category-vaccine
                </Link>
              </li>
              {/* <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/maps") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/maps"
                >
                  <i
                    className={
                      "fas fa-map-marked mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/maps") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Maps
                </Link>
              </li> */}
            </ul>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            {/* <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Auth Layout Pages
            </h6> */}
            {/* Navigation */}

            <ul  className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
         
              <li  className="items-center">
                
                {/* <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  to="/"
                > */}
                
                <button onClick = {handleLogout}>
                <img
                
                src={LogoutLogo} alt ="..."
                style={{height:"15px",marginRight:"8px",display:"inline"}}
                className={
                    
                  (window.location.href.indexOf("/admin/dashboard") !== -1
                    ? "opacity-75"
                    : "text-blueGray-300")
                }
                 
                ></img>{" "}
                  LOGOUT
                  </button>
                {/* </Link> */}
              </li>

              {/* <li className="items-center">
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  to="/auth/register"
                >
                  <i className="fas fa-clipboard-list text-blueGray-300 mr-2 text-sm"></i>{" "}
                  Register
                </Link>
              </li> */}
            </ul>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            {/* <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              No Layout Pages
            </h6>
            {/* Navigation */}
            {/*     
            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="items-center">
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  to="/landing"
                >
                  <i className="fas fa-newspaper text-blueGray-400 mr-2 text-sm"></i>{" "}
                  Landing Page
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  to="/profile"
                >
                  <i className="fas fa-user-circle text-blueGray-400 mr-2 text-sm"></i>{" "}
                  Profile Page
                </Link>
              </li>
            </ul>  */}

            {/* Divider */}
            {/* <hr className="my-4 md:min-w-full" /> */}
          </div>
        </div>
      </nav>
    </>
  );
}
