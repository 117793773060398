export const checkString = (name) => {
    let validstring = true ;
    let errMsg = '';
    // let cityname = !/[^a-z A-Z]/.test(name) ;
    // if(!cityname){
    //    errMsg = "Should Contains Only Characters";
    //    validstring = false
    // }
    if(name?.length >= 99){
      errMsg = "Field cannot have more than 100 digits";
      validstring = false
    }
   return {validstring,errMsg};
}

export const valueCapitalizer = (value) => {
    var splitStr = value.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
   let newvalue = splitStr.join(' '); 
   return newvalue 
}


export const checkImageType = (files) => {
    const filetype = files?.type || ""
    let errMsg = '';
    let validimage = false
    let checkimage = filetype.search(/(jpg|png|jpeg|jfif)/g)
    if(checkimage >= 1) {
      errMsg = "File Not Valid";
      validimage = true
    }
    return {validimage,errMsg}
  }

