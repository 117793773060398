import React, { useState, useCallback, useEffect } from "react";
import axios from "../../api";
import { validateData, getLocations } from "../../util";
import { ToastContainer, toast } from "react-toastify";
import { Continents } from "util/constant";
import { checkImageType } from "util/inputhelper";

// components

export default function CardContinent({ fetch, deleted, setDeleted }) {
  const [data, setData] = useState({});
  const [validateErr, setValidateErr] = useState({});
  const [continents, setContinents] = useState(Continents);
  const [files, setFiles] = useState();
  const [disable,setDisable]=useState(false);

  const fetchContinent = async () => {
    const res = await getLocations("continent");
    if (res.locations) {
      const continentsName = res.locations.map((d, ind) => d?.name);
      let oldData = [...Continents];
      const newContinents = oldData.filter(
        (continent) => !continentsName.includes(continent.name)
      );
      setContinents(newContinents);
    }
  };

  useEffect(() => {
    fetchContinent();
  }, []);

  useEffect(() => {
    if (deleted === true) {
      fetchContinent();
    setDeleted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleted]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateData(name, value, validateErr, setValidateErr);
    const newdata = continents.filter(continent => continent.name === value)[0];
    setData({ ...newdata });
    setDisable(false)
  };
  
  const handleFetch = useCallback(() => {
    fetch();
  }, [fetch]);

  const isValid = () => {
    let valid = true;
    if (
      validateData(
        "continent",
         data?.name,
        validateErr,
        setValidateErr
      ) === false
    ) {
      return false;
    }
    if (!files?.name) {
      valid = false;
      validateErr.files = "This field is required";
      setValidateErr({ ...validateErr });
    }
    return valid;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validate = isValid();
    if (validate) {
      try {
        setDisable(true)
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("position", 1);
        formData.append("type", "CONTINENT");
        formData.append("photos", files);
        formData.append('latitude',data.latitude)
        formData.append('longitude',data.longitude)
        const res = await axios.post(`location/create`, formData);
        if (res.status === 201) {   
          toast.success("Data added Successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          await fetchContinent() ;
          
          setData({})
          return handleFetch();
        }
      } catch (err) {
        setDisable(false)
        toast.error("Data Not Submitted", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  const handleFileUpload = (e) => {
    const files = e.target.files[0];
    const {validimage,errMsg} = checkImageType(files)
     if(validimage){
      setValidateErr({ ...validateErr, files: null });
      return setFiles(files);
     }
     else{
          validateErr.files = errMsg;
          setValidateErr({ ...validateErr });
          toast.error("File Not Valid", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
     }
    e.target.value = ""
    return setFiles();
  };
  return (
    <>
    <ToastContainer/>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Add Continent
            </h6>         
          </div>
        </div>
       
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="mb-4 text-gray-700">
              <label className="block mb-1" htmlFor="forms-validationInputCode_error">
                Continent name
              </label>
              <select
                onChange={handleChange}
                name="continent"
                className="w-1/2 h-10 border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                value={data?.name || 'All continents have been selected'}
                
              >
                {continents.length === 0 ? (
                  <option disabled>
                    All continents have been selected
                  </option>
                ) : (
                  <option>
                    Select
                  </option>
                )}
                {continents.map((continent, index) => (
                  <option value={continent.name || ""} key={index}>
                    {continent.name}
                  </option>
                ))}
              </select>
              <br></br>
              {validateErr && (
                <span
                  style={{ color: "red" }}
                  className="text-xs text-red-700"
                  id="passwordHelp"
                >
                  {validateErr?.continent}
                </span>
              )}
            </div>
            <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Images
                  </label>
                  <input
                    onChange={(e) => handleFileUpload(e)}
                    name="images"
                    type="file"
                    value = {files?.images ||""}
                    className="form-control"
                    id="customFile"
                    style = {{
                      outline:'none'

                    }}
                  />
                  <br/> 
                  <label style = {{color:'grey'}} >(png,jpg,jpeg,jfif are allowed)</label>
                </div>
                
                {validateErr && (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    {validateErr?.files}
                  </small>
                )}
                
              </div>
            <button
              disabled={disable}
              type="submit"
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 mb-3 ease-linear transition-all duration-150"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
