import React from "react";
import PropTypes from "prop-types";
// components

import TableDropdown from "components/Dropdowns/TableDropdown.js";

function CardTable({
  color,
  title,
  handleEditCard,
  fetch,
  data,
  cols,
  isCity,
  isCategoryVaccine,
  isCountry,
  isBlog,
  isVaccine,
  isCategory,
  setDeleted,
  isContinent,
}) {
  
  const [dropdownpopoverid, setDropdownPopoverId] = React.useState("");
  const emptytable ="No Data found"
  return (
    <>
   
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table  className="items-center break-words w-full bg-transparent border-collapse">
            <thead>
              <tr>
                {cols &&
                  cols.map((col, ind) => (
                    <th style={{ width:'15%'}}
                      key={ind}
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0  font-semibold text-left " +
                        (color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                    >
                      {col}
                    </th>
                  ))}
                <th style={{ width:'10%'}}
                  className={
                    "px-6 align-middle vertical-align: baseline; border border-solid py-3 text-xs uppercase border-l-0 border-r-0  font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                ></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((dt) => (
                <tr key={dt.id}>
                  {!isCategory && !isCategoryVaccine && <th className="border-t-0 px-6  vertical-align: baseline; border-l-0 border-r-0 text-xs  p-4 text-left items-center">
                    <span
                    style={{width:'10px', wordBreak:'break-word'}}
                      className={
                        "ml-4 font-bold " +
                        +(color === "light"
                          ? "text-blueGray-600"
                          : "text-white")
                      }
                    >
                      {dt.name}
                    </span>
                  </th>}
                  {
                 
                 isCategoryVaccine &&
                     <th style={{ width:'30%'}} className="border-t-0 px-6 align-middle vertical-align: baseline; border-l-0 border-r-0 text-xs   text-left items-center">
                    <span
                 style={{display:"flex"}}
                      className={
                        "ml-4 font-bold " +
                        +(color === "light"
                          ? "text-blueGray-600"
                          : "text-white")
                      }
                    >
                     { dt.title}
                    </span>
                  </th>
                 
                  }
                  {isCountry && (
                    <>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs  p-4">
                        {dt.parent ? `${dt.parent}` : "--"}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs  p-4">
                        {dt.trending ? "True" : "False"}
                      </td>
                      
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs  p-4">
                        {dt?.latitude ?? "--"}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs  p-4">
                        {dt.longitude ?? "--"}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs  p-4">
                        <div className="flex">
  
                            <img
                            // key = {i}
                              src={process.env.REACT_APP_API + dt?.photos[dt?.photos.length -1].path}
                              alt="..."
                              className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow"
                            />
                          {/* ))} */}
                        </div>
                      </td>
                    </>
                  )}
                   
                  {isCity && (
                    <>
                     
                      <td style={{textDecoration:"none", wordBreak:"break-all", padding:"24px"}}>
                        {dt.parent ? `${dt.parent}` : "--"}
                      </td>
                      {/* <td className="border-t-0 px-6 border-l-0 border-r-0 text-xs  p-4">
                        {dt.trending ? "True" : "False"}
                      </td> */}
    
                      <td className="border-t-0 px-6  border-l-0 border-r-0 text-xs  p-4">
                        <div className="flex">
                          <img
                            src={
                              process.env.REACT_APP_API +
                              dt?.photos[dt?.photos.length -1].path
                            }
                            alt="..."
                            className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow"
                          />
                        </div>
                      </td>
                      <td>
                        <abbr style={{textDecoration:"none",width:"50px", wordBreak:"break-all"}} title={dt?.description}> {dt?.description?.split("",120).join("")}</abbr>
                      </td>
                    </>
                  )}
                  {isBlog && (
                    <>
                      <td className="border-t-0 px-6  border-l-0 border-r-0 text-xs  p-4">
                        {dt?.type ?? "--"}
                      </td>
                      <td style={{wordBreak:'break-all'}} className={ 
                        " ml-3 font-bold border-t-0 px-6  text-xs	 text-right" +
                        +(color === "light"
                          ? "text-blueGray-600"
                          : "text-white")
                      }>
                        {dt.description ?? "--"}
                      </td>
                      <td style={{wordBreak:'break-all'}} className="border-t-0 px-6  border-l-0 border-r-0 text-xs  p-4">
                        {dt.createdBy ?? "--"}
                      </td>
                      <td className="border-t-0 px-6  border-l-0 border-r-0 text-xs  p-4">
                        <div className="flex">
                          <img
                            src={
                              process.env.REACT_APP_API +
                              dt?.photos[dt?.photos.length -1].path
                            }
                            alt="..."
                            className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow"
                          />
                        </div>
                      </td>
                    </>
                  )}
                  {isContinent && (
                    <>
                    
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs  p-4">
                        <div className="flex">
                    {  dt?.photos  ? <img
                            src={
                              process.env.REACT_APP_API +
                              dt?.photos[dt?.photos?.length -1]?.path ?? ""
                            }
                            alt="..."
                            className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow"
                          /> : "__"}
                          {/* {dt?.photos?.map((p,i) => (
                            <img
                              key = {i}
                              src={process.env.REACT_APP_API + p.path}
                              alt="..."
                              className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow"
                            />
                          ))} */}
                        </div>
                      </td>
                    </>
                  )}

                  {isVaccine && (
                   
                    <>               
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs  p-4">
                        {dt.dose ?? "--"}
                      </td>
                    </>
                  )}
                   {isCategory && (
                    <>  

                      <td style={{overflowWrap: "anywhere",width:'40%',wordBreak:"break-all"}}className={
                        "  ml-3 font-bold border-t-0 px-6 break-words align-middle text-xs	 text-right" +
                        +(color === "light"
                          ? "text-blueGray-600"
                          : "text-white")
                      }>
                        {dt.title ?? "--"}     
                      </td>
                      <td style={{width:'40%',wordBreak:"break-all"}}  dangerouslySetInnerHTML={{__html: dt.description ?? "--"}} className="border-t-0 px-6  align-middle border-l-0 border-r-0 text-xs  p-4"/>
                    </>
                  )}
                  {isCategoryVaccine && (
                    <>  
                
                      <td className={
                        " ml-3  border-t-0 px-6 align-middle text-xs	 text-right" +
                        +(color === "light"
                          ? "text-blueGray-600"
                          : "text-white")
                      }>
                        {dt.vac}                       
                      </td>
                      <td className={
                        " ml-3  border-t-0 px-6 align-middle text-xs	 text-right" +
                        +(color === "light"
                          ? "text-blueGray-600"
                          : "text-white")
                      }>
                        {dt.location}

                      </td>
                    </>
                  )}
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs  p-4 text-right">
                    <TableDropdown
                      handleEditCard={handleEditCard}
                      id={dt.id}
                      fetch={fetch}            
                      type={title}
                      setDeleted={setDeleted}
                      row={dt}
                      showdropdownpopover={
                        dropdownpopoverid === dt.id ? true : false
                      }
                      setDropdownPopoverId={setDropdownPopoverId}
                    />
                  </td>
                </tr>             
              ))}     
            </tbody>
          </table>
         
        </div>
        
      </div>
      <div>

      {
     <small  style={{ color: "grey",fontSize:"20px" ,textAlign:"center" }}>
                  <h1>
   {data?.length === 0? emptytable:""}
 
   </h1>
                  </small>
                }
      </div>
    </>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
export default React.memo(CardTable);