import React, { useState, useEffect, useCallback,useRef } from "react";
import axios from "../../api";
import { ToastContainer, toast } from "react-toastify";
import { checkString,checkImageType} from "util/inputhelper";
// components

export default function CardEditContinents({
  location,
  fetch,
  handleEditCard,
  setEditcard
}) {
  const [data, setData] = useState({});
  const [validateErr, setValidateErr] = useState({});
// const [isTrending, setTrending] = useState(false);
  const [files, setFiles] = useState();
  const [showfiles, setShowfiles] = useState();

  let formref = useRef(null)
  useEffect(()=>{
    var elem =  formref.current.getBoundingClientRect()
    window.scroll({
      top: window.scrollY+elem.y-formref.current.offsetTop,
      behavior: 'smooth',
   });
  })


  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setData({ ...data, [name]: value });
  //   ValidateContinent({ ...data, [name]: value });
  // };

  useEffect(() => {
    if (location.name) {
      data.country = location.name;
      data.continent = location.parentId;
      data.id = location.id;
      data.description = location.description
      data.latitude = location.latitude
      data.longitude = location.longitude
      setData({ ...data });
      // setTrending(location.trending);
      if (location.photos && location.photos.length > 0) {
        setShowfiles(process.env.REACT_APP_API + location.photos[location.photos.length -1].name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const isValid = () => {
    let valid = true;
    if (!files && !showfiles ) {
            valid = false;
      validateErr.files = "This field is required";
      setValidateErr({ ...validateErr });
    }
    return valid;
  };

  const handleFetch = useCallback(() => {
    fetch();
  }, [fetch]);

  const handleFileUpload = (e) => {
    const files = e.target.files[0]; 
    var reader = new FileReader();
      reader.readAsDataURL(files);const {validimage,errMsg} = checkImageType(files)

      if(validimage){
      
      setValidateErr({ ...validateErr, files: null });
    reader.addEventListener("load", function () {
      setShowfiles(this.result);
    });
    return setFiles(files);

     }
     else{
          validateErr.files = errMsg;
          setValidateErr({ ...validateErr });
          toast.error("File Not Valid", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
     }
    e.target.value = ""
    return setFiles();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validateData = isValid(data);
    const {validstring,errMsg} = checkString(data.country)
    if (validateData && validstring) {
      try {
        const formData = new FormData();
        formData.append("id", data.id);
        formData.append("photos", files);
        // formData.append("type","continent")
        const res = await axios.post(`location/create`, formData);
        if (res.status === 201) {
          toast.success("Data Update Successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
           setEditcard({});
           return handleFetch();
        }
      } catch (err) {
        toast.error("Failed To Update", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    if(!validstring){
      validateErr.country = errMsg
      setValidateErr({ ...validateErr });
    }
  };
    return (
    <>
      <ToastContainer/>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Edit Continent
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0" >
          <form onSubmit={(e) => handleSubmit(e)} ref = {formref}>
            <div className="flex flex-wrap">
            <div className="w-full lg:w-6/12 px-2">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Continent name
                </label>
                <div className="relative w-full mb-3">
                  <select
                    name="continent"
                    className="border-0 px-2 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue={location.name}
                  >
                    
                    <option disabled value={location.name || ""}>
                    {location.name}
                    </option>
                
                  </select>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <div>
                  <img className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow" src = {showfiles} alt ="..." height = '60' width = '60'/>
                  </div>
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Images
                  </label>
                  <input
                    onChange={(e) => handleFileUpload(e)}
                    value={data?.images || ""}
                    name="images"
                    type="file"
                    className="form-control"
                    id="customFile"
                    style={{width:"100px",color:"transparent"}}
                  />
                  <br/>
                  <label style = {{color:'grey'}}>(png,jpg,jpeg,jfif are allowed)</label>
                </div>
                {validateErr && (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    {validateErr?.files}
                  </small>
                )}
              </div>
           
            </div>
            <button
              type="submit"
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
            >
              Submit
            </button>
            <button
              className="bg-red-500 text-white active:bg-red-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
              onClick={(e) => {
                e.preventDefault();
                handleEditCard(false);
              }}
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
