import React, { useEffect, useState } from "react";

// components
import CardTable from "components/Cards/CardTable.js";
import CardAddState from "components/Cards/CardAddState";
import { getLocations } from "../../util";

export default function State() {
  const [countries, setCountry] = useState([]);
  const [states, setStates] = useState([]);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const countries = await getLocations("country");
    if (countries.locations) setCountry(countries.locations);
    const states = await getLocations("states");
    if (states.locations) return setStates(states.locations);
  };
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <CardAddState fetch={fetch} locations={countries} />
        </div>
        <div className="w-full mb-12 px-4">
          <CardTable locations={states} title={"States"} />
        </div>
      </div>
    </>
  );
}
