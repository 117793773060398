import React, { useEffect, useState } from "react";

// components

import CardAddContinents from "components/Cards/CardAddContinents";
// import CardTable from "components/Cards/CardTable.js";
 import CardEditContinents from "components/Update/CardEditContinents";

import CardTable from "components/Cards/CardTableNew";
import { getLocations } from "../../util";

export default function Continents() {
  const [continents, setContinents] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const [editCard, setEditcard] = useState({});

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const res = await getLocations("continent");
    if (res.locations) {
      return setContinents(res.locations);
    } 
  };
  const handleEditCard = (row) => {
    setEditcard(row);
  }
  return (
    <>
      <div className="flex flex-wrap">
        {(editCard && editCard.id )? (
          <div className="w-full lg:w-12/12 px-4">
            <CardEditContinents
            location={editCard}
              fetch={fetch}
              category={editCard}
              setEditcard={setEditcard}
              handleEditCard={handleEditCard}

            />
          </div>
        ):<div className="w-full lg:w-12/12 px-4">
          <CardAddContinents fetch={fetch} deleted={deleted} setDeleted={setDeleted}/>
      </div>} 
      <div className="w-full mb-12 px-4">
          <CardTable
            fetch={fetch}
            title="Continents"
            handleEditCard={setEditcard}
            cols={["Name","Images"]}
            data={continents}
            isContinent={true}
            setDeleted={setDeleted}
          />
        </div>
      </div> 









    </>
  );
}
