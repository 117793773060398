import React, { useState, useCallback } from "react";
import axios from "../../api";
import { validateData} from "../../util";
import { ToastContainer, toast } from "react-toastify";
// components

export default function CardAddVaccine({ fetch }) {
  const [data, setData] = useState({});
  const [validateErr, setValidateErr] = useState({});
  const [disable,setDisable]=useState(false);
  const handleChange = (event) => {
    const { name, value } = event.target;
    validateErr[name]=null 
    setValidateErr({...validateErr})   
    setData({ ...data, [name]: value })
    setDisable(false);;
  };
  const handleFetch = useCallback(() => {
    fetch();
  }, [fetch]);

  const isValid = () => {
    let valid = true;
    if (
      validateData("name", data?.name, validateErr, setValidateErr) === false
    ) {
      valid = false;
    }
    if (
      validateData("dose", data?.dose, validateErr, setValidateErr) === false
    ) {
      valid = false;
    }
    return valid;
  };

  const cleanAllField = () => {
    setData({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();  
    const validate = isValid();
    if (validate) {   
      try {
        setDisable(true)
        let formdata =data
        const res = await axios.post(`vaccine/create`, formdata);  
        if (res.status === 201) {
          toast.success("Data added successfully",{           
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          cleanAllField();       
          return handleFetch();       
        }
        else if(!res?.data?.id){  
          setDisable(true);
          toast.error("Duplicate Vaccine Name should not be added.", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        setDisable(false);
        toast.error("Data Not Submitted", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">Add Vaccine</h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Name
                  </label>
                  <input
                  maxLength="200"
                    value={data?.name || ""}
                    name="name"
                    onChange={handleChange}
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                  {validateErr && (
                    <small style={{ color: "red", fontSize: "14px" }}>
                      {validateErr?.name}
                    </small>
                  )}
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Dose
                  </label>
                  <input
                     maxLength="200"
                    value={data?.dose || ""}
                    name="dose"
                    onChange={handleChange}
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />

                  {validateErr && (
                    <small style={{ color: "red", fontSize: "14px" }}>
                      {validateErr?.dose}
                    </small>
                  )}
                </div>
              </div>
            </div>
            <button
            disabled={disable}
              type="submit"
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
