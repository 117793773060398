import axios from "axios";
const instance = axios.create({
  baseURL: process.env.REACT_APP_API+"api/",
});
instance.interceptors.request.use(async function(config){
  const token = localStorage.getItem("userToken");
    if (token) {
      config.headers["x-access-token"] = token;
    }
  return config;
}, error => {
  return Promise.reject(error)
})

export default instance;
