import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views
import Vaccine from "views/admin/Vaccine";
import Dashboard from "views/admin/Dashboard.js";
// import Maps from "views/admin/Maps.js";
import Continents from "views/admin/Continents.js";
import Tables from "views/admin/Tables.js";
import Country from "views/admin/Country";
import Activities from "views/admin/Activities";
import Blog from "views/admin/Blog";
import State from "views/admin/state";
import City from "views/admin/city";
import Users from "views/admin/Users";
import Categoryvaccine from "views/admin/categoryvaccine";
//osho_file_rename
import Category from "views/admin/Category";
export default function Admin(props) {
  return (
    <>
      <Sidebar {...props}/>
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats/>
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />
            {/* <Route path="/admin/maps" exact component={Maps} /> */}
            <Route path="/admin/continent" exact component={Continents} />
            <Route path="/admin/tables" exact component={Tables} />
            <Route path="/admin/country" exact component={Country} />
            <Route path="/admin/Vaccine" exact component={Vaccine} />
            <Route path="/admin/Category" exact component={Category} />
            <Route path="/admin/activities" exact component={Activities} />
            <Route path="/admin/blog" exact component={Blog} />
            <Route path="/admin/state" exact component={State} />
            <Route path="/admin/city" exact component={City} />
            <Route path="/admin/users" exact component={Users} />
            <Route path = "/admin/categories-vaccines" component = {Categoryvaccine}/>
            <Redirect from="/" to="/admin/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
